/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Spýtajte sa knižnice"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"n02fz00obj"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Tribečská knižnica v Topoľčanoch"}>
              </Subtitle>

              <Text className="text-box" content={"+421 38 746 3511<br>tribecska@kniznicatopolcany.sk<br>Čsl. armády 28, 995 35 Topoľčany"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé meno","type":"text","required":true,"placeholder":"Zadajte celé meno"},{"name":"Kontaktný e-mail","type":"email","required":true,"placeholder":"Zadajte Váš e-mail"},{"name":"Správa","type":"textarea","required":true,"placeholder":"Zadajte text správy"},{"name":"Odoslať","type":"submit","align":"left"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="css-w2kes2 css-42e4bw pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25013/b9ebef300a8e405b91cbb83a104b8309_bri=115__s=3000x_.JPG);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--white);\">„Najväčším pokladom je dobrá knižnica.“&nbsp;</span>"}>
              </Title>

              <Text className="text-box text-box--right fs--30" content={"<span style=\"color: var(--color-blend--95); font-weight: bold;\">Vissarion Grigorjevič Belinskij</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--color-dominant)\">Tribečská knižnica v Topoľčanoch</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--color-dominant)\">2023</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}